/**
 * Created by jerry on 2020/03/5.
 * 支付api
 */
import * as API from '@/api/index'

export default {
    // 支付列表
    getPaymet: params => {
        return API.POST('api/paymethod/all', params)
    },
    //新增支付
    createPaymet: params => {
        return API.POST('api/paymethod/create', params)
    },
    //编辑支付
    updatePaymet:params => {
        return API.POST('api/paymethod/update', params)
    },
    //支付顺序调整
    movePaymet:params => {
        return API.POST('api/paymethod/move', params)
    },

}